import { TypeDataProduct, TypeDataProducts } from ".";
import Loader, { LoaderEvents } from "../loader";

export default class Products {
    private _data: TypeDataProducts;
    private _byCat: { [categoryId: string]: TypeDataProduct[] };
    private _loader: Loader;

    get data() { return this._data; }
    get byCat() { return this._byCat; }

    constructor(loader: Loader) {
        this._loader = loader;
        loader.add('s_data/products.json', "products");
        loader.once(LoaderEvents.Complete, () => {
            this._data = loader.resources["products"] as TypeDataProducts;

            // populate products by categoryId
            this._byCat = {};
            this._data.products.forEach(item => {
                if (!this._byCat[item.categoryId])
                    this._byCat[item.categoryId] = [];

                this._byCat[item.categoryId].push(item);
            });
        });
    }
}